import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
  Spinner,
  Alert,
  Badge,
  Dropdown
} from "react-bootstrap";
import {
  Search,
  ShoppingCart,
  SlidersHorizontal,
  ChevronDown,
  Grid,
  List,
  ArrowUpDown
} from 'lucide-react';
import { listProducts } from "../actions/productActions";

const ProductCard = ({ product, onAddToCart }) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  return (
    <Card className="h-100 border-0 shadow-sm product-card">
       
      <div className="position-relative">
      <Link to={`/product/${product._id}`}>
        <Card.Img
          variant="top"
          src={product.image}
          className="p-3"
          style={{ height: '200px', objectFit: 'contain' }}
        />
        </Link>
        {product.countInStock === 0 && (
          <Badge 
            bg="danger" 
            className="position-absolute top-0 end-0 m-2"
          >
            Out of Stock
          </Badge>
        )}
        {product.isNew && (
          <Badge 
            bg="success" 
            className="position-absolute top-0 start-0 m-2"
          >
            New
          </Badge>
        )}
      </div>
      <Card.Body className="d-flex flex-column">
      <Link to={`/product/${product._id}`}>
        <Card.Title className="mb-2">
          {product.name}
        </Card.Title>
        </Link>
        <div className="text-muted small mb-2">{product.category}</div>
        <div className="mt-auto">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="mb-0">{formatPrice(product.price)}</h5>
            {product.originalPrice && (
              <span className="text-decoration-line-through text-muted small">
                {formatPrice(product.originalPrice)}
              </span>
            )}
          </div>
          <Button
            variant={product.countInStock === 0 ? "secondary" : "primary"}
            className="w-100"
            onClick={() => onAddToCart(product._id)}
            disabled={product.countInStock === 0}
          >
            <ShoppingCart size={16} className="me-2" />
            {product.countInStock === 0 ? 'Out of Stock' : 'Add to Cart'}
          </Button>
        </div>
      </Card.Body>
 
    </Card>
  );
};

const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  // Local state
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("newest");
  const [viewMode, setViewMode] = useState("grid");
  const [priceRange, setPriceRange] = useState({ min: "", max: "" });
  const [selectedCategory, setSelectedCategory] = useState("");

  // Redux state
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  // Handlers
  const addToCartHandler = (id) => {
    navigate(`/cart/${id}?qty=1`);
  };

  // Filter and sort products
  const getFilteredProducts = () => {
    let filtered = [...(products || [])];

    // Search filter
    if (searchTerm) {
      filtered = filtered.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Category filter
    if (selectedCategory) {
      filtered = filtered.filter(product => 
        product.category === selectedCategory
      );
    }

    // Price range filter
    if (priceRange.min) {
      filtered = filtered.filter(product => 
        product.price >= Number(priceRange.min)
      );
    }
    if (priceRange.max) {
      filtered = filtered.filter(product => 
        product.price <= Number(priceRange.max)
      );
    }

    // Sort
    switch (sortBy) {
      case "price-asc":
        filtered.sort((a, b) => a.price - b.price);
        break;
      case "price-desc":
        filtered.sort((a, b) => b.price - a.price);
        break;
      case "name":
        filtered.sort((a, b) => a.name.localeCompare(b.name));
        break;
      default:
        // "newest" - assuming newer products have higher IDs
        filtered.sort((a, b) => b._id.localeCompare(a._id));
    }

    return filtered;
  };

  const categories = [...new Set(products?.map(p => p.category) || [])];
  const filteredProducts = getFilteredProducts();

  return (
    <Container fluid className="py-4">
      {/* Header Section */}
      <Row className="mb-4">
        <Col>
          <h1 className="mb-0">Our Products</h1>
          <p className="text-muted">Discover our collection of premium products</p>
        </Col>
      </Row>

      {/* Filters and Search Section */}
      <Row className="mb-4 g-3">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text className="bg-white">
              <Search size={18} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
        
        <Col md={2}>
          <Form.Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
        </Col>

        <Col md={2}>
          <InputGroup>
            <Form.Control
              placeholder="Min Price"
              type="number"
              value={priceRange.min}
              onChange={(e) => setPriceRange(prev => ({ ...prev, min: e.target.value }))}
            />
          </InputGroup>
        </Col>

        <Col md={2}>
          <InputGroup>
            <Form.Control
              placeholder="Max Price"
              type="number"
              value={priceRange.max}
              onChange={(e) => setPriceRange(prev => ({ ...prev, max: e.target.value }))}
            />
          </InputGroup>
        </Col>

        <Col md={2}>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" className="w-100">
              <ArrowUpDown size={16} className="me-2" />
              Sort By
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item 
                active={sortBy === "newest"}
                onClick={() => setSortBy("newest")}
              >
                Newest First
              </Dropdown.Item>
              <Dropdown.Item 
                active={sortBy === "price-asc"}
                onClick={() => setSortBy("price-asc")}
              >
                Price: Low to High
              </Dropdown.Item>
              <Dropdown.Item 
                active={sortBy === "price-desc"}
                onClick={() => setSortBy("price-desc")}
              >
                Price: High to Low
              </Dropdown.Item>
              <Dropdown.Item 
                active={sortBy === "name"}
                onClick={() => setSortBy("name")}
              >
                Name
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {/* Results Summary */}
      <Row className="mb-4">
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-muted">
              Showing {filteredProducts.length} products
            </span>
            <div className="btn-group">
              <Button
                variant={viewMode === "grid" ? "primary" : "outline-primary"}
                onClick={() => setViewMode("grid")}
              >
                <Grid size={16} />
              </Button>
              <Button
                variant={viewMode === "list" ? "primary" : "outline-primary"}
                onClick={() => setViewMode("list")}
              >
                <List size={16} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      {/* Products Grid */}
      {loading ? (
        <div className="text-center py-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Row className="g-4">
          {filteredProducts.map((product) => (
            <Col key={product._id} 
              xs={12} 
              md={viewMode === "grid" ? 6 : 12} 
              lg={viewMode === "grid" ? 4 : 12} 
              xl={viewMode === "grid" ? 3 : 12}
            >
              <ProductCard
                product={product}
                onAddToCart={addToCartHandler}
              />
            </Col>
          ))}
          {filteredProducts.length === 0 && (
            <Col xs={12}>
              <Alert variant="info">
                No products found matching your criteria.
              </Alert>
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
};

export default HomeScreen;