import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Form, Button, Badge, Spinner, Alert } from 'react-bootstrap';
import { Truck, CreditCard, Package, ArrowLeft, Edit2, Save, X } from 'lucide-react';
import { useSelector } from 'react-redux';

const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { userInfo }= useSelector(state => state.userLogin);
 
  // Edit form state
  const [formData, setFormData] = useState({
    isDelivered: false,
    isPaid: false,
    deliveryNotes: '',
    trackingNumber: '',
    paymentStatus: '',
    deliveryStatus: ''
  });

  // Fetch order details
  const fetchOrder = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_URL}/api/orders/${id}`,{
        headers: {
          'Authorization': `Bearer ${userInfo.token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch order details');
      
      const data = await response.json();
      setOrder(data);
      setFormData({
        isDelivered: data.isDelivered,
        isPaid: data.isPaid,
        deliveryNotes: data.deliveryNotes || '',
        trackingNumber: data.trackingNumber || '',
        paymentStatus: data.paymentStatus || '',
        deliveryStatus: data.deliveryStatus || ''
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Update order
  const updateOrder = async () => {
    try {
      setUpdating(true);
      const response = await fetch(`${process.env.REACT_APP_URL}/api/orders/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
           'Authorization': `Bearer ${userInfo.token}`

        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) throw new Error('Failed to update order');
      
      const updatedOrder = await response.json();
      setOrder(updatedOrder);
      setEditMode(false);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [id]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString();
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container fluid className="py-4">
      <Row className="mb-4">
        <Col>
          <Button variant="outline-secondary" onClick={() => window.history.back()} className="mb-3">
            <ArrowLeft size={16} className="me-2" />
            Back to Orders
          </Button>
          <div className="d-flex justify-content-between align-items-center">
            <h2>Order #{order._id}</h2>
            {/* {!editMode ? (
              <Button variant="primary" onClick={() => setEditMode(true)}>
                <Edit2 size={16} className="me-2" />
                Edit Order
              </Button>
            ) : (
              <div>
                <Button variant="success" onClick={updateOrder} className="me-2" disabled={updating}>
                  <Save size={16} className="me-2" />
                  Save Changes
                </Button>
                <Button variant="outline-secondary" onClick={() => setEditMode(false)}>
                  <X size={16} className="me-2" />
                  Cancel
                </Button>
              </div>
            )} */}
          </div>
        </Col>
      </Row>

      <Row className="g-4">
        {/* Order Summary Card */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Order Summary</h5>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <strong>Order Date:</strong>
                  <div>{formatDate(order.createdAt)}</div>
                </Col>
                <Col>
                  <strong>Last Updated:</strong>
                  <div>{formatDate(order.updatedAt)}</div>
                </Col>
              </Row>
              
              <Row className="mb-3">
                <Col>
                  <strong>Payment Status:</strong>
                  <div>
                    <Badge bg={order.isPaid ? 'success' : 'danger'}>
                      {order.isPaid ? 'Paid' : 'Unpaid'}
                    </Badge>
                  </div>
                </Col>
                <Col>
                  <strong>Delivery Status:</strong>
                  <div>
                    <Badge bg={order.isDelivered ? 'success' : 'warning'}>
                      {order.isDelivered ? 'Delivered' : 'Pending'}
                    </Badge>
                  </div>
                </Col>
              </Row>

              {editMode && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Payment Status</Form.Label>
                    <Form.Select
                      value={formData.isPaid.toString()}
                      onChange={(e) => setFormData(prev => ({ ...prev, isPaid: e.target.value === 'true' }))}
                    >
                      <option value="true">Paid</option>
                      <option value="false">Unpaid</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Delivery Status</Form.Label>
                    <Form.Select
                      value={formData.isDelivered.toString()}
                      onChange={(e) => setFormData(prev => ({ ...prev, isDelivered: e.target.value === 'true' }))}
                    >
                      <option value="true">Delivered</option>
                      <option value="false">Pending</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Tracking Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.trackingNumber}
                      onChange={(e) => setFormData(prev => ({ ...prev, trackingNumber: e.target.value }))}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Delivery Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={formData.deliveryNotes}
                      onChange={(e) => setFormData(prev => ({ ...prev, deliveryNotes: e.target.value }))}
                    />
                  </Form.Group>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Customer Details Card */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Customer Details</h5>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                <strong>Name:</strong>
                <div>{order.user?.name}</div>
              </div>
              <div className="mb-3">
                <strong>Email:</strong>
                <div>{order.user?.email}</div>
              </div>
              <div className="mb-3">
                <strong>Shipping Address:</strong>
                <div>{order.shippingAddress?.address}</div>
                <div>{order.shippingAddress?.city}, {order.shippingAddress?.state} {order.shippingAddress?.postalCode}</div>
                <div>{order.shippingAddress?.country}</div>
              </div>
              <div>
                <strong>Phone:</strong>
                <div>{order.shippingAddress?.phone || 'N/A'}</div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Order Items Card */}
        <Col md={12}>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Order Items</h5>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems.map((item) => (
                    <tr key={item._id}>
                      <td>
                        <div className="d-flex align-items-center">
                          {item.image && (
                            <img
                              src={item.image}
                              alt={item.name}
                              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '1rem' }}
                            />
                          )}
                          <div>
                            <div>{item.name}</div>
                            <small className="text-muted">SKU: {item?.product?._id}</small>
                          </div>
                        </div>
                      </td>
                      <td>{formatPrice(item.price)}</td>
                      <td>{item.qty}</td>
                      <td>{formatPrice(item.price * item.qty)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  {/* <tr>
                    <td colSpan="3" className="text-end"><strong>Subtotal:</strong></td>
                    <td>{formatPrice(order.itemsPrice)}</td>
                  </tr> */}
                  <tr>
                    <td colSpan="3" className="text-end"><strong>Shipping:</strong></td>
                    <td>{formatPrice(order.shippingPrice)}</td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-end"><strong>Tax:</strong></td>
                    <td>{formatPrice(order.taxPrice)}</td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-end"><strong>Total:</strong></td>
                    <td><strong>{formatPrice(order.totalPrice)}</strong></td>
                  </tr>
                </tfoot>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        {/* Payment Details Card */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Payment Details</h5>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                <strong>Payment Method:</strong>
                <div>{order.paymentMethod}</div>
              </div>
              {order.isPaid && (
                <>
                  <div className="mb-3">
                    <strong>Paid At:</strong>
                    <div>{formatDate(order.paidAt)}</div>
                  </div>
                  <div>
                    <strong>Transaction ID:</strong>
                    <div>{order.paymentResult?.id || 'N/A'}</div>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Shipping Details Card */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Shipping Details</h5>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                <strong>Tracking Number:</strong>
                <div>{order.trackingNumber || 'Not available'}</div>
              </div>
              {order.isDelivered && (
                <div className="mb-3">
                  <strong>Delivered At:</strong>
                  <div>{formatDate(order.deliveredAt)}</div>
                </div>
              )}
              <div>
                <strong>Delivery Notes:</strong>
                <div>{order.deliveryNotes || 'No notes available'}</div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetails;