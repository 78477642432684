import React, { useState, useEffect } from 'react';
import { Store, Phone, Mail, FileText, Shield } from 'lucide-react';

const Footer = () => {
  const [settings, setSettings] = useState({
    about: '',
    phone: '',
    email: '',
    termsOfService: '',
    privacyPolicy: '',
    showTerms: false,
    showPrivacy: false
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/settings`);
      const data = await response.json();
      setSettings(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to load settings:', error);
      setLoading(false);
    }
  };

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  if (window.location.pathname === "/" && !window.location.origin.includes("localhost") && !window.location.origin.includes("127.0.0.1")) {
    return null;
  }
  if (loading) {
    return null;
  }

  return (
    <>
      <footer className="bg-light py-4 mt-auto">
        <div className="container">
          <div className="row g-4">
            {/* About Section */}
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center gap-2 mb-3">
                <Store className="text-primary" size={24} />
                <h5 className="mb-0">About Us</h5>
              </div>
              <p className="text-muted">{settings.about}</p>
            </div>

            {/* Contact Section */}
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center gap-2 mb-3">
                <Phone className="text-primary" size={24} />
                <h5 className="mb-0">Contact Us</h5>
              </div>
              <ul className="list-unstyled text-muted">
                {settings.phone && (
                  <li className="mb-2">
                    <div className="d-flex align-items-center gap-2">
                      <Phone size={16} />
                      <a href={`tel:${settings.phone}`} className="text-decoration-none text-muted">
                        {settings.phone}
                      </a>
                    </div>
                  </li>
                )}
                {settings.email && (
                  <li>
                    <div className="d-flex align-items-center gap-2">
                      <Mail size={16} />
                      <a href={`mailto:${settings.email}`} className="text-decoration-none text-muted">
                        {settings.email}
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            {/* Legal Links Section */}
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center gap-2 mb-3">
                <Shield className="text-primary" size={24} />
                <h5 className="mb-0">Legal</h5>
              </div>
              <ul className="list-unstyled">
                {settings.showTerms && (
                  <li className="mb-2">
                    <button
                      className="btn btn-link text-muted p-0 text-decoration-none"
                      onClick={() => setShowTermsModal(true)}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <FileText size={16} />
                        Terms of Service
                      </div>
                    </button>
                  </li>
                )}
                {settings.showPrivacy && (
                  <li>
                    <button
                      className="btn btn-link text-muted p-0 text-decoration-none"
                      onClick={() => setShowPrivacyModal(true)}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <Shield size={16} />
                        Privacy Policy
                      </div>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="border-top mt-4 pt-4 text-center text-muted">
            <small>© {new Date().getFullYear()} CAMHK. All rights reserved.</small>
          </div>
        </div>
      </footer>

      {/* Terms of Service Modal */}
      {showTermsModal && (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Terms of Service</h5>
                <button type="button" className="btn-close" onClick={() => setShowTermsModal(false)}></button>
              </div>
              <div className="modal-body">
                <div style={{ whiteSpace: 'pre-wrap' }}>{settings.termsOfService}</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowTermsModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Privacy Policy Modal */}
      {showPrivacyModal && (
        <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Privacy Policy</h5>
                <button type="button" className="btn-close" onClick={() => setShowPrivacyModal(false)}></button>
              </div>
              <div className="modal-body">
                <div style={{ whiteSpace: 'pre-wrap' }}>{settings.privacyPolicy}</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowPrivacyModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;