import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Badge,
  Alert,
  Spinner,
  Breadcrumb,
  Tab,
  Tabs,
  Toast
} from "react-bootstrap";
import {
  ShoppingCart,
  Share2,
  ArrowLeft,
  Truck,
  Shield,
  RefreshCcw,
  Check,
  Info
} from 'lucide-react';
import { listProductDetails } from "../actions/productActions";

const ProductScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Local state
  const [qty, setQty] = useState(1);
  const [showToast, setShowToast] = useState(false);

  // Redux state
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    dispatch(listProductDetails(id));
  }, [dispatch, id]);

  const addToCartHandler = () => {
    setShowToast(true);
    navigate(`/cart/${id}?qty=${qty}`);
  };

  // Format price with commas and decimals
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  // Calculate discount percentage
  const calculateDiscount = (original, current) => {
    return Math.round(((original - current) / original) * 100);
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      {/* Breadcrumb Navigation */}
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/products" }}>Products</Breadcrumb.Item>
        <Breadcrumb.Item active>{product?.category}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        {/* Product Images */}
        <Col lg={6} className="mb-4">
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <div className="position-relative">
                <img
                  src={product?.image}
                  alt={product?.name}
                  className="img-fluid rounded"
                  style={{ width: '100%', height: 'auto' }}
                />
                {product?.countInStock === 0 && (
                  <div className="position-absolute top-0 end-0 m-3">
                    <Badge bg="danger" className="px-3 py-2">Out of Stock</Badge>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Product Details */}
        <Col lg={6}>
          <div className="sticky-top" style={{ top: '2rem' }}>
            {/* Product Title and Basic Info */}
            <div className="mb-4">
              <h1 className="h2 mb-2">{product?.name}</h1>
            </div>

            {/* Price Section */}
            <Card className="border-0 shadow-sm mb-4">
              <Card.Body>
                <div className="mb-3">
                  <h2 className="h3 mb-0">{formatPrice(product?.price)}</h2>
                  {product?.originalPrice && (
                    <div className="d-flex align-items-center gap-2">
                      <span className="text-decoration-line-through text-muted">
                        {formatPrice(product?.originalPrice)}
                      </span>
                      <Badge bg="success">
                        {calculateDiscount(product?.originalPrice, product?.price)}% OFF
                      </Badge>
                    </div>
                  )}
                </div>

                {/* Stock and Quantity Selection */}
                <div className="mb-3">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <Info size={16} />
                    <span className={product?.countInStock > 0 ? 'text-success' : 'text-danger'}>
                      {product?.countInStock > 0 ? `${product?.countInStock} units in stock` : 'Out of Stock'}
                    </span>
                  </div>

                  {product?.countInStock > 0 && (
                    <Form.Group className="mb-3">
                      <Form.Label>Quantity</Form.Label>
                      <Form.Select
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}
                      >
                        {[...Array(Math.min(product?.countInStock, 10)).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="d-grid gap-2">
                  <Button
                    size="lg"
                    onClick={addToCartHandler}
                    disabled={product?.countInStock === 0}
                  >
                    <ShoppingCart size={20} className="me-2" />
                    Add to Cart
                  </Button>
                  {/* <Button variant="outline-primary" size="lg">
                    <Share2 size={20} className="me-2" />
                    Share Product
                  </Button> */}
                </div>
              </Card.Body>
            </Card>

            {/* Product Benefits */}
            <Card className="border-0 shadow-sm mb-4">
              <Card.Body>
                <div className="d-flex gap-4">
                  <div className="text-center">
                    <Truck size={24} className="mb-2 text-primary" />
                    <div className="small">Free Shipping</div>
                  </div>
                  <div className="text-center">
                    <Shield size={24} className="mb-2 text-primary" />
                    <div className="small">1 Year Warranty</div>
                  </div>
                  <div className="text-center">
                    <RefreshCcw size={24} className="mb-2 text-primary" />
                    <div className="small">30-Day Returns</div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            {/* Product Details Tabs */}
            <Tabs defaultActiveKey="description" className="mb-4">
              <Tab eventKey="description" title="Description">
                <div className="p-4 bg-light rounded">
                  <p>{product?.description}</p>
                </div>
              </Tab>
              <Tab eventKey="specifications" title="Specifications">
                <div className="p-4 bg-light rounded">
                  {/* Add product specifications here */}
                  <p>Product specifications coming soon...</p>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>

      {/* Success Toast */}
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className="position-fixed bottom-0 end-0 m-4"
      >
        <Toast.Header>
          <Check size={18} className="me-2 text-success" />
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>Product added to cart successfully!</Toast.Body>
      </Toast>
    </Container>
  );
};

export default ProductScreen;