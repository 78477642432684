import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import CommonFooter from "./components/CommonFooter";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import LandingPage from "./screens/LandingPage";
import axios from "axios";
import ContactScreen from "./screens/ContactScreen";
import Settings from "./screens/StoreSettings";
import OrdersList from "./screens/OrdersListScreen";
import AdminOrderScreen from "./screens/AdminOrderScreen";


const App = () => {
  return (
    <Router>
       <div className="min-vh-100 d-flex flex-column">
      <Header />
      {/* <Container> */}
      {/* <main className="py-3"> */}
      
      <main className="p-3 flex-grow-1">
        <div style={{ marginBottom: "100px" }} />
        <Routes>
          <Route path="/home" element={<LandingPage />} exact />
          <Route path="/products" element={<HomeScreen />} exact />
          <Route path="/product/:id" element={<ProductScreen />} />
          <Route path="/cart/:id" element={<CartScreen />} />
          <Route path="/cart/" element={<CartScreen />} />
          <Route path="/login" element={<LoginScreen />}></Route>
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/shipping" element={<ShippingScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/orders/:id" element={<OrderScreen />} />
          <Route path="/admin/orderlist/:id" element={<AdminOrderScreen />} />
          <Route path="/admin/orderlist" element={<OrdersList />} />
          <Route path="/admin/userslist" element={<UserListScreen />} />
          <Route path="/admin/productlist" element={<ProductListScreen />} />
          <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
          <Route
            path="/admin/product/:id/edit"
            element={<ProductEditScreen />}
          />
          <Route path="/admin/settings" element={<Settings />} />
        </Routes>
      </main>
      {/* </Container> */}
      <CommonFooter />
      </div>
    </Router>
  );
};

export default App;
