import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Form, Button, Badge, Spinner, Alert, Card } from 'react-bootstrap';
import { Search, ChevronUp, ChevronDown, RotateCw } from 'lucide-react';
import { useSelector } from 'react-redux';

const OrdersDashboard = () => {
  // State management
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const { userInfo }= useSelector(state => state.userLogin);
  // Filters and pagination state
  const [filters, setFilters] = useState({
    isPaid: '',
    isDelivered: '',
    paymentMethod: '',
    minTotalPrice: '',
    maxTotalPrice: '',
    search: '',
    sortBy: 'createdAt',
    order: 'desc',
    page: 1,
    limit: 10
  });

  // Fetch orders with current filters
  const fetchOrders = async () => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams();

      // Add filters to query params
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== '') {
          queryParams.append(key, value);
        }
      });

      const response = await fetch(`${process.env.REACT_APP_URL}/api/orders?${queryParams.toString()}`,{
        headers: {
          'Authorization': `Bearer ${userInfo.token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch orders');

      const data = await response.json();
      setOrders(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search handler
  const handleSearch = (value) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeout = setTimeout(() => {
      setFilters(prev => ({ ...prev, search: value, page: 1 }));
    }, 500);
    setSearchTimeout(timeout);
  };

  // Fetch orders when filters change
  useEffect(() => {
    fetchOrders();
    return () => {
      if (searchTimeout) clearTimeout(searchTimeout);
    };
  }, [filters]);

  // Handle sort toggle
  const handleSort = (field) => {
    setFilters(prev => ({
      ...prev,
      sortBy: field,
      order: prev.sortBy === field && prev.order === 'desc' ? 'asc' : 'desc'
    }));
  };

  // Format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  // Format price
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  // Reset filters
  const resetFilters = () => {
    setFilters({
      isPaid: '',
      isDelivered: '',
      paymentMethod: '',
      minTotalPrice: '',
      maxTotalPrice: '',
      search: '',
      sortBy: 'createdAt',
      order: 'desc',
      page: 1,
      limit: 10
    });
  };

  return (
    <Container fluid className="py-4">
      <Card>
        <Card.Header>
          <h2 className="mb-0">Orders Dashboard</h2>
        </Card.Header>
        <Card.Body>
          {/* Filters Section */}
          <Row className="mb-4 g-3">
            <Col md={3}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search orders..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Select
                value={filters.isPaid}
                onChange={(e) => setFilters(prev => ({ ...prev, isPaid: e.target.value, page: 1 }))}
              >
                <option value="">Payment Status</option>
                <option value="true">Paid</option>
                <option value="false">Unpaid</option>
              </Form.Select>
            </Col>
            <Col md={2}>
              <Form.Select
                value={filters.isDelivered}
                onChange={(e) => setFilters(prev => ({ ...prev, isDelivered: e.target.value, page: 1 }))}
              >
                <option value="">Delivery Status</option>
                <option value="true">Delivered</option>
                <option value="false">Pending</option>
              </Form.Select>
            </Col>
            <Col md={2}>
              <Form.Control
                type="number"
                placeholder="Min Price"
                value={filters.minTotalPrice}
                onChange={(e) => setFilters(prev => ({ ...prev, minTotalPrice: e.target.value, page: 1 }))}
              />
            </Col>
            <Col md={2}>
              <Form.Control
                type="number"
                placeholder="Max Price"
                value={filters.maxTotalPrice}
                onChange={(e) => setFilters(prev => ({ ...prev, maxTotalPrice: e.target.value, page: 1 }))}
              />
            </Col>
            <Col md={1}>
              <Button variant="outline-secondary" onClick={resetFilters} className="w-100">
                <RotateCw size={16} className="me-2" />
              </Button>
            </Col>
          </Row>

          {/* Error Alert */}
          {error && (
            <Alert variant="danger" className="mb-4">
              {error}
            </Alert>
          )}

          {/* Orders Table */}
          {loading ? (
            <div className="text-center py-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table responsive hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('_id')} style={{ cursor: 'pointer' }}>
                    Order ID
                    {filters.sortBy === '_id' && (
                      filters.order === 'desc' ? <ChevronDown className="ms-1" /> : <ChevronUp className="ms-1" />
                    )}
                  </th>
                  <th onClick={() => handleSort('createdAt')} style={{ cursor: 'pointer' }}>
                    Date
                    {filters.sortBy === 'createdAt' && (
                      filters.order === 'desc' ? <ChevronDown className="ms-1" /> : <ChevronUp className="ms-1" />
                    )}
                  </th>
                  <th>Customer</th>
                  <th onClick={() => handleSort('totalPrice')} style={{ cursor: 'pointer' }}>
                    Total
                    {filters.sortBy === 'totalPrice' && (
                      filters.order === 'desc' ? <ChevronDown className="ms-1" /> : <ChevronUp className="ms-1" />
                    )}
                  </th>
                  <th>Payment Status</th>
                  <th>Delivery Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{formatDate(order.createdAt)}</td>
                    <td>{order.user?.name || 'N/A'}</td>
                    <td>{formatPrice(order.totalPrice)}</td>
                    <td>
                      <Badge bg={order.isPaid ? 'success' : 'danger'}>
                        {order.isPaid ? 'Paid' : 'Unpaid'}
                      </Badge>
                    </td>
                    <td>
                      <Badge bg={order.isDelivered ? 'success' : 'warning'}>
                        {order.isDelivered ? 'Delivered' : 'Pending'}
                      </Badge>
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => window.location.href = `/admin/orderlist/${order._id}`}
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/* Pagination */}
          <Row className="mt-4 align-items-center">
            <Col md={4}>
              <div className='d-flex align-items-center'>
                Page: {filters.page}

              <Form.Select
                value={filters.limit}
                onChange={(e) => setFilters(prev => ({ ...prev, limit: Number(e.target.value), page: 1 }))}
                style={{ width: 'auto' }}
                >
                <option value="10">10 per page</option>
                <option value="25">25 per page</option>
                <option value="50">50 per page</option>
              </Form.Select>
                </div>
            </Col>

            <Col md={8} className="text-end">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => {
                  if (filters.page === 1) return;
                  setFilters(prev => ({ ...prev, page: prev.page - 1 }))
                }}
              >
                Previous
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => setFilters(prev => ({ ...prev, page: prev.page + 1 }))}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OrdersDashboard;