import React, { useState, useEffect } from 'react';
import { Store, Phone, Mail, FileText, Shield, AlertCircle } from 'lucide-react';
import { useSelector } from 'react-redux';

const Settings = () => {
  const [settings, setSettings] = useState({
    about: '',
    phone: '',
    email: '',
    termsOfService: '',
    privacyPolicy: '',
    showTerms: false,
    showPrivacy: false
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const { userInfo }= useSelector(state => state.userLogin);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/settings`);
      const data = await response.json();
      setSettings(data);
      setLoading(false);
    } catch (error) {
      setAlert({
        show: true,
        message: 'Failed to load settings',
        type: 'error'
      });
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userInfo.token}`
        },
        body: JSON.stringify(settings),
      });
      
      if (response.ok) {
        setAlert({
          show: true,
          message: 'Settings saved successfully!',
          type: 'success'
        });
      } else {
        throw new Error('Failed to save');
      }
    } catch (error) {
      setAlert({
        show: true,
        message: 'Failed to save settings',
        type: 'error'
      });
    } finally {
      setSaving(false);
      setTimeout(() => setAlert({ show: false, message: '', type: '' }), 3000);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid  py-4">
      <div className="container">
        {/* Header */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex align-items-center gap-2 mb-2">
              <Store className="text-primary" size={32} />
              <h1 className="h2 mb-0">Settings</h1>
            </div>
            <p className="text-muted">Manage your store's general information</p>
          </div>
        </div>

        {/* Alert */}
        {alert.show && (
          <div className={`alert ${alert.type === 'success' ? 'alert-success' : 'alert-danger'} d-flex align-items-center`} role="alert">
            <AlertCircle className="me-2" size={20} />
            <div>{alert.message}</div>
          </div>
        )}

        <div className="row g-4">
          {/* About Section */}
          <div className="col-12 col-lg-6">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center gap-2 mb-3">
                  <Store className="text-primary" size={24} />
                  <h5 className="card-title mb-0">About Your Store</h5>
                </div>
                <textarea
                  className="form-control"
                  rows="4"
                  value={settings.about}
                  onChange={(e) => setSettings({ ...settings, about: e.target.value })}
                  placeholder="Tell customers about your business..."
                />
              </div>
            </div>
          </div>

          {/* Contact Section */}
          <div className="col-12 col-lg-6">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center gap-2 mb-3">
                  <Phone className="text-success" size={24} />
                  <h5 className="card-title mb-0">Contact Information</h5>
                </div>
                <div className="mb-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={settings.phone}
                    onChange={(e) => setSettings({ ...settings, phone: e.target.value })}
                  />
                </div>
                <div>
                  <label className="form-label">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    value={settings.email}
                    onChange={(e) => setSettings({ ...settings, email: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Terms of Service Section */}
          <div className="col-12 col-lg-6">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center gap-2 mb-3">
                  <FileText className="text-warning" size={24} />
                  <h5 className="card-title mb-0">Terms of Service</h5>
                </div>
                <textarea
                  className="form-control mb-3"
                  rows="6"
                  value={settings.termsOfService}
                  onChange={(e) => setSettings({ ...settings, termsOfService: e.target.value })}
                  placeholder="Enter your terms of service..."
                />
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="showTerms"
                    checked={settings.showTerms}
                    onChange={(e) => setSettings({ ...settings, showTerms: e.target.checked })}
                  />
                  <label className="form-check-label" htmlFor="showTerms">
                    Display Terms of Service on website
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Privacy Policy Section */}
          <div className="col-12 col-lg-6">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body">
                <div className="d-flex align-items-center gap-2 mb-3">
                  <Shield className="text-danger" size={24} />
                  <h5 className="card-title mb-0">Privacy Policy</h5>
                </div>
                <textarea
                  className="form-control mb-3"
                  rows="6"
                  value={settings.privacyPolicy}
                  onChange={(e) => setSettings({ ...settings, privacyPolicy: e.target.value })}
                  placeholder="Enter your privacy policy..."
                />
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="showPrivacy"
                    checked={settings.showPrivacy}
                    onChange={(e) => setSettings({ ...settings, showPrivacy: e.target.checked })}
                  />
                  <label className="form-check-label" htmlFor="showPrivacy">
                    Display Privacy Policy on website
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Save Button */}
        <div className="row mt-4">
          <div className="col-12 d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={handleSave}
              disabled={saving}
            >
              {saving ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;